import React, {useEffect} from 'react'
import WebFont from 'webfontloader';
import Moment from 'react-moment';
import moment from 'moment';
// eslint-disable-next-line
import {BrowserRouter as Router, Switch, Route, Link} from "react-router-dom";
import {Container, Row, Card, Button, Table, Dropdown, DropdownButton, Badge} from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faPlusCircle, faGavel, faEye, faCoins, faCashRegister, faDollarSign} from '@fortawesome/free-solid-svg-icons'
import NavBar from "../navbar/Navbar";
import {get} from '../../../controllers/endpoints.controller';
import {getUserToken} from '../../../controllers/user.controller';
import { pn } from '../../../tools/chains';

export default function CourtFiles(){

    const PAGE_TITLE = "Administración Colegios";
    const PAGE_ICON = faCoins;
    const [tableColumns, setTableColumns] = React.useState([]);
    const [tableRows, setTableRows] = React.useState([]);
    
    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Roboto:100,300,400']
            }
        });

        document.title = `EAC - ${PAGE_TITLE}`;
        getCourtFiles();
    }, []);

    const setupTable = function(){
        const cols = ['Mora', 'Colegio', 'Monto', 'Ejecutado', 'Fecha', 'Estado', 'Acciones'];
        setTableColumns(cols);
    };

    const getCourtFiles = async function(){
        

        const data = [
            {mora: "Flia. Fernández", school: <Badge bg='' className='content-table status' style={{backgroundColor: 'green'}}>Colegio Fasta San Vicente de Paul</Badge>, status: <Badge bg='' className='content-table status' style={{backgroundColor: 'green'}}>Pago completo</Badge>, amount: pn(3000.00), executed: pn(3000.00), date: moment(), action: <Button className='buttonBlue' variant="primary"><FontAwesomeIcon icon={faEye} /></Button>},
            {mora: "Flia. Fernández", school: <Badge bg='' className='content-table status' style={{backgroundColor: 'green'}}>Colegio Fasta San Vicente de Paul</Badge>, status: <Badge bg='warning' className='content-table status'>Pago parcial</Badge>, amount: pn(3000.00), executed: pn(3000.00), date: moment(), action: <span><Button className='buttonBlue' variant="success"><FontAwesomeIcon icon={faDollarSign} /></Button><Button className='buttonBlue ms-2' variant="primary"><FontAwesomeIcon icon={faEye} /></Button></span>},
            {mora: "Flia. Fernández", school: <Badge bg='' className='content-table status' style={{backgroundColor: 'green'}}>Colegio Fasta San Vicente de Paul</Badge>, status: <Badge bg='' className='content-table status' style={{backgroundColor: 'red'}}>Pago pendiente</Badge>, amount: pn(3000.00), executed: pn(3000.00), date: moment(), action: <span><Button className='buttonBlue' variant="success"><FontAwesomeIcon icon={faDollarSign} /></Button><Button className='buttonBlue ms-2' variant="primary"><FontAwesomeIcon icon={faEye} /></Button></span>}
        ]

        setTableRows(data);
            setupTable();
    }

    return(
        <div className='p-0' style={{fontFamily: 'Roboto'}}>
            <style>{'body {background-color: #EBEBEB !important;}'}</style>
            <NavBar/>

            <div style={{height: 300, background: '#FAFAFA'}}>
                <Container>
                    <Row style={{paddingTop: 10}}>
                        <table className='w-75' style={{marginLeft: 'auto', marginRight: 'auto'}}>
                            <tr>
                                <td style={{fontSize: 45, fontWeight: 300}}>
                                <span style={{color: '#A0A0A0'}}>{PAGE_TITLE}</span>
                                </td>
                                <td style={{fontSize: 140, width: 150}}>
                                    <FontAwesomeIcon icon={PAGE_ICON} style={{fontSize: 140, color: "A0A0A0"}} />
                                </td>
                            </tr>
                        </table>
                    </Row>
                </Container>
            </div>

            <Container>
                <Row style={{marginTop: -70}}>
                    <Card className='cards content mb-5'>
                        <table className='content-options content'>
                            <tr>
                                <td className='content-options rows left'>
                                    
                                </td>
                                <td className='content-options rows right'>
                                    <Button variant="success"><FontAwesomeIcon icon={faPlusCircle} style={{marginRight: 8}} />Nuevo pago</Button>
                                </td>
                            </tr>
                        </table>

                        <table striped hover className='mb-3'>
                            <thead>
                                <tr>
                                    {tableColumns.map((c, i) => (
                                        <th key={i} className='content-table head'>{c}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {tableRows.map((r, i) => (
                                    <tr key={i} >
                                        <td className='content-table rows middle align-middle'>{r.mora}</td>
                                        <td className='content-table rows middle align-middle'>{r.school}</td>
                                        <td className='content-table rows middle align-middle' style={{width: 150}}>{r.amount}</td>
                                        <td className='content-table rows middle align-middle' style={{width: 150}}>{r.executed}</td>
                                        <td className='content-table rows middle align-middle' style={{width: 100}}>{<Moment format="DD/MM/YYYY">{r.createdAt}</Moment>}</td>
                                        <td className='content-table rows middle align-middle' style={{width: 150}}>{r.status}</td>
                                        <td className='content-table rows middle align-middle' style={{width: 150}}>{r.action}</td>
                                    </tr>
                                ))}
                                
                            </tbody>
                        </table>
                    </Card>
                </Row>
            </Container>
        </div>
    )
}